import createUI, { CLIENT_TYPE, CLIENT_VERSION } from '@duf/client-web';
import { init, trackEvent, setProperty, getPlatform } from './analytics';

window.onload = () => {
  const apiURL = process.env.API_URL;
  const artifactsUrl = process.env.ARTIFACTS_URL;
  const target = location.host;

  const manifestUrl = new URL(apiURL + '/manifest');
  const sp = manifestUrl.searchParams;

  sp.set('t', target);
  sp.set('ss', [window.screen.width, window.screen.height].join('|'));
  sp.set('ct', CLIENT_TYPE);
  sp.set('cv', CLIENT_VERSION);

  if (window.devicePixelRatio) sp.set('px', window.devicePixelRatio);
  if (window.navigator.language) sp.set('l', window.navigator.language);

  const platform = getPlatform();
  if (platform) sp.set('pl', platform);

  fetch(manifestUrl, { mode: 'cors', credentials: 'include' })
    .then(async (response) => {
      if (!response.ok) {
        throw new Error('Failed to load manifest');
      }

      const manifest = await response.json();
      const {
        id,
        targetId,
        deploymentId,
        landingId,
        testId,
        testVariantId,
        artifactId,
        title,
        backgroundColor,
        vars,
        paymentProvider,
        csrf,
      } = manifest;

      setProperty('title', title);
      setProperty('targetName', target);
      setProperty('targetId', targetId);
      setProperty('variantId', id);
      setProperty('deploymentId', deploymentId);
      setProperty('landingId', landingId);
      setProperty('clientType', CLIENT_TYPE);
      setProperty('clientVersion', CLIENT_VERSION);

      if (testId) {
        setProperty('testId', testId);

        if (testVariantId) {
          setProperty('testVariantId', testVariantId);
        }
      }

      init(csrf);

      document.title = title;
      document.body.style.backgroundColor = backgroundColor;

      const variant = `${artifactId}-${id}`;
      const varsHash = vars ? JSON.stringify(vars) : '';

      if (
        window.localStorage.getItem('variant') !== variant ||
        window.localStorage.getItem('varsHash') !== varsHash // If test was updated
      ) {
        window.localStorage.setItem('variant', variant);
        window.localStorage.setItem('varsHash', varsHash);
        // TODO: make it as configuration to createUI so we do not need to know where and how to clear it
        window.sessionStorage.removeItem('duf-state');
      }

      const schemaResponse = await fetch(`${artifactsUrl}/${artifactId}/schema.json`);
      const schema = await schemaResponse.json();

      createUI('#app', {
        schema,
        paymentProvider,
        customVariables: vars,
        onNavigate: (screen, formData) => trackEvent('navigate', { screen, formData }),
        onOpenOverlay: (screen) => trackEvent('overlay_open', { screen }),
        onCloseOverlay: (screen) => trackEvent('overlay_close', { screen }),
        onReady: (app) => {
          const unsubscribe = app.subscribeToScreenEvents(
            (eventName, event) => {
              const eventsToTrack = [
                'onClick',
                'onSubmit',
                'onPaymentSuccess',
                'onPaymentFail',
              ];
              if (eventsToTrack.includes(eventName)) {
                trackEvent(eventName, {
                  target: {
                    id: event?.target?.id,
                    tag: event?.target?.tagName?.toLowerCase(),
                    class: event?.target?.className,
                    text: event?.target?.textContent?.substring?.(0, 100),
                  },
                  screen: app.currentOverlay?.id || app.currentScreen?.id,
                  data: event?.data,
                });
              }
            },
          );

          const unsubscribeActions = app.subscribeToScreenActions(
            (actionName, action, params) => {
              const actionsToTrack = ['SendDataAction'];
              if (actionsToTrack.includes(actionName)) {
                trackEvent(actionName, {
                  url: params.url,
                  method: params.method,
                  data: params.data,
                  screen: app.currentOverlay?.id || app.currentScreen?.id,
                });
              }
            },
          );
        },
        loadProductsInfo:
          paymentProvider &&
          paymentProvider.productsInfoUrl &&
          (async (products) => {
            const response = await fetch(paymentProvider.productsInfoUrl, {
              method: 'POST',
              mode: 'cors',
              credentials: 'include',
              headers: {
                'content-type': 'application/json',
                'x-csrf-token': csrf,
              },
              body: JSON.stringify({ products }),
            });
            return response.json();
          }),
        createPaymentIntent:
          paymentProvider &&
          paymentProvider.intentUrl &&
          (async (data) => {
            const response = await fetch(paymentProvider.intentUrl, {
              method: 'POST',
              mode: 'cors',
              credentials: 'include',
              headers: {
                'content-type': 'application/json',
                'x-csrf-token': csrf,
              },
              body: JSON.stringify(data),
            });
            return response.json();
          }),
      });
    })
    .catch(console.error);
};
